// Entry point for the build script in your package.json
// import './channels/**/*_channel.js'

require("@rails/activestorage").start()
require("@rails/ujs").start()
require("local-time").start()

import "trix"
import "@rails/actiontext"

import "@hotwired/turbo-rails"
import "@fortawesome/fontawesome-free/js/all"

import "./controllers"
import * as bootstrap from "bootstrap"
import PureCounter from "@srexi/purecounterjs"

import Swiper from "swiper/bundle"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "chartkick/chart.js"

document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  )
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  )
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  const pure = new PureCounter()

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }
  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    if (all) {
      select(el, all).forEach((e) => e.addEventListener(type, listener))
    } else {
      select(el, all).addEventListener(type, listener)
    }
  }

  /**
   * Easy on scroll event listener
   */
  const onscroll = (el, listener) => {
    el.addEventListener("scroll", listener)
  }

  /**
   * Sidebar toggle
   */
  if (select(".toggle-sidebar-btn")) {
    on("click", ".toggle-sidebar-btn", function (e) {
      select("body").classList.toggle("toggle-sidebar")
    })
  }

  /**
   * Initiate Bootstrap validation check
   */
  var needsValidation = document.querySelectorAll(".needs-validation")

  Array.prototype.slice.call(needsValidation).forEach(function (form) {
    form.addEventListener(
      "submit",
      function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add("was-validated")
      },
      false
    )
  })
  if (select(".mobile-nav-toggle")) {
    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile")
      this.classList.toggle("bi-list")
      this.classList.toggle("bi-x")
    })
  }

  const swiper = new Swiper(".clients-slider", {
    speed: 400,
    loop: true,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
    },
    slidesPerView: "auto",
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      480: {
        slidesPerView: 3,
        spaceBetween: 60,
      },
      640: {
        slidesPerView: 4,
        spaceBetween: 80,
      },
      992: {
        slidesPerView: 6,
        spaceBetween: 120,
      },
    },
  })
})

Turbo.setConfirmMethod((message, element) => {
  let dialog = document.getElementById("turbo-confirm")
  dialog.querySelector("p").textContent = message
  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener(
      "close",
      () => {
        resolve(dialog.returnValue == "confirm")
      },
      { once: true }
    )
  })
})

document.addEventListener("turbo:frame-load", (event) => {
  const spinner = document.querySelectorAll(".spinner")
  const overlay = document.querySelectorAll(".overlay")

  if (spinner.length > 0) {
    spinner.forEach((el) => el.remove())
  }

  if (overlay.length > 0) {
    overlay.forEach((el) => (el.style.zIndex = -1))
  }
})
