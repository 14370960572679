import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit-on-change"
export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTargets.forEach((input) => {
      input.addEventListener("change", this.submitForm.bind(this))
    })
  }

  disconnect() {
    this.inputTargets.forEach((input) => {
      input.removeEventListener("change", this.submitForm.bind(this))
    })
  }

  submitForm(event) {
    event.preventDefault()
    this.element.requestSubmit()
  }
}
