import { Controller } from "@hotwired/stimulus"
import { DataTable } from "simple-datatables"

export default class extends Controller {
  connect() {
    const dataTable = new DataTable(this.element, {
      labels: {
        placeholder: "Rechercher...",
        searchTitle: "Rechercher dans le tableau",
        pageTitle: "Page {page}",
        perPage: "entrées par page",
        noRows: "Aucune entrée trouvée",
        info: "Affichage de {start} à {end} sur {rows} entrées",
        noResults: "Aucun résultat trouvé pour votre recherche",
      },
      perPage: 10,
      perPageSelect: [10, 25, 50, 100, 200],
      rowRender: function (rownValue, tr, index) {
        // Assuming you have the 'tr' object as described in your question

        tr.attributes.id = `school_${rownValue[0].text}`
      },
      tableRender: function (data, table, type) {
        Object.assign(table.childNodes[1], { attributes: { id: "schools" } })
      },
      columns: [
        { select: 0, headerClass: "w-5" },
        { select: 1, headerClass: "w-20" },
        { select: [2, 3], headerClass: "w-8" },
        { select: [4, 5], cellClass: "text-nowrap" },
        { select: 6, headerClass: "w-20" },
        { select: 7, headerClass: "w-20" },
        {
          select: 8,
          type: "html",
          sortable: false,
          headerClass: "text-center w-10",
          cellClass: "text-center",
          render: (data, td, rowIndex, cellIndex) => {
            return `<a href="${data[1].attributes.href}">
            <i class="fa fa-calendar" aria-hidden="true"></i>
            </a>`
          },
        },
        {
          select: 9,
          type: "html",
          headerClass: "text-center w-10",
          cellClass: "text-center",
          sortable: false,
          render: (data, td, rowIndex, cellIndex) => {
            return `<a href="${data[1].attributes.href}">
            <i class="fa fa-history" aria-hidden="true"></i>
            </a>`
          },
        },
        {
          select: 10,
          type: "html",
          headerClass: "text-center w-9",
          cellClass: "text-center",
          sortable: false,
          render: (data, td, rowIndex, cellIndex) => {
            return `<a href="${data[1].attributes.href}">
            <i class="fa fa-calendar-check" aria-hidden="true"></i>
            </a>`
          },
        },
        {
          select: 11,
          type: "html",
          sortable: false,

          // render: (data, td, rowIndex, cellIndex) => {
          //   return `<a title="${data[1].attributes.title}" id="${data[1].attributes.id}" rel="nofollow" data-method="patch" href="${data[1].attributes.href}">
          //   <i class="${data[1].childNodes[1].attributes.class}"></i></a>`
          // },
        },
        {
          select: 12,
          type: "html",
          sortable: false,
          render: (data, td, rowIndex, cellIndex) => {
            // return `<a data-turbo-frame='modal' title="${data[1].attributes.title}" rel="nofollow" data-method="patch" href="${data[1].attributes.href}">
            // <i class="fa-solid fa-pen-to-square"></i>
            // </a>`
          },
        },
        {
          select: 13,
          sortable: false,
          type: "html",
          // render: (data, td, rowIndex, cellIndex) => {
          //   return `<a title="${data[1].attributes.title}" rel="nofollow" data-method="patch" data-confirm="Êtes-vous sûr?" href="${data[1].attributes.href}">
          //   <i class="fa-solid fa-trash-can"></i>
          //   </a>`
          // },
        },
      ],
    })
  }
}
