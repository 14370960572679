import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"
// import { Fireworks } from "fireworks-js"

export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element)
  }

  open() {
    if (!this.modal.isOpened) {
      this.modal.show()
      // const tutorForm = document.querySelector("#tutor-form")
      // const fire = document.querySelector("#fireworks")
      // const tutorId = document.querySelector("#tutor_id")

      // if (tutorId) {
      //   tutorId.addEventListener("change", (e) => {
      //     window.tutorValue = e.target.value
      //   })
      // }

      // if (fire && tutorForm) {
      //   const el = document.createElement("div")
      //   el.setAttribute("id", "manage-tutor-form")
      //   fire.prepend(el)
      // }
    }
  }

  close(event) {
    // const fire = document.querySelector("#fireworks")
    // const manageTutorForm = document.querySelector("#manage-tutor-form")

    // if (
    //   fire &&
    //   manageTutorForm &&
    //   window.tutorValue &&
    //   window.tutorValue !== "Selectionner un tuteur"
    // ) {
    //   const fireworks = new Fireworks(fire, {
    //     autoresize: true,
    //     opacity: 0.5,
    //     acceleration: 1.05,
    //     friction: 0.97,
    //     gravity: 1.5,
    //     particles: 50,
    //     traceLength: 3,
    //     traceSpeed: 10,
    //     explosion: 5,
    //     intensity: 30,
    //     flickering: 50,
    //     lineStyle: "round",
    //     hue: {
    //       min: 0,
    //       max: 360,
    //     },
    //     delay: {
    //       min: 10,
    //       max: 15,
    //     },
    //     rocketsPoint: {
    //       min: 50,
    //       max: 50,
    //     },
    //     lineWidth: {
    //       explosion: {
    //         min: 1,
    //         max: 3,
    //       },
    //       trace: {
    //         min: 1,
    //         max: 2,
    //       },
    //     },
    //     brightness: {
    //       min: 50,
    //       max: 80,
    //     },
    //     decay: {
    //       min: 0.015,
    //       max: 0.03,
    //     },
    //     mouse: {
    //       click: true,
    //       move: true,
    //       max: 1,
    //     },
    //   })
    //   if (event.detail.success) {
    //     this.modal.hide()

    //     fire.classList.remove("d-none")
    //     fire.innerHTML = ""
    //     fireworks.start()
    //   }
    // } else {
    // }
    if (event.detail.success) {
      this.modal.hide()
    }

    // function delay(time) {
    //   return new Promise((resolve) => setTimeout(resolve, time))
    // }
    // delay(3000).then(() => {
    //   fire.classList.add("d-none")
    // })
  }
}
