import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner", "modal"]

  showSpinner() {
    const overlay = document.querySelectorAll(".overlay")
    const spinner = document.createElement("div")
    spinner.style.position = "fixed"

    spinner.classList.add("spinner")
    document.body.appendChild(spinner)

    if (overlay.length > 0) {
      overlay.forEach((el) => (el.style.zIndex = 2))
    }
  }
}
