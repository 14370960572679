import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "cardElement",
    "errorElement",
    "submitButton",
    "total",
    "addToCartButton",
  ]

  static values = {
    stripePublicKey: String,
  }

  connect() {
    this.stripe = Stripe(this.stripePublicKeyValue)
    this.elements = this.stripe.elements()
    this.card = this.elements.create("card")
    if (location.pathname === "/parent/cart") {
      this.card.mount(this.cardElementTarget)
    }
    this.updateAddToCartButtons()
    this.setupTurboStreamListener()
  }

  setupTurboStreamListener() {
    document.addEventListener("turbo:before-stream-render", (event) => {
      if (event.target.action === "update_buttons") {
        event.preventDefault()
        this.updateAddToCartButtons()
      }
    })
  }

  updateAddToCartButtons() {
    this.addToCartButtonTargets.forEach((button) => {
      const realWeekId = button.dataset.realWeekId
      const isInCart = this.isRealWeekInCart(realWeekId)

      button.disabled = isInCart
      button.textContent = isInCart ? "En panier" : "Ajouter au panier"
    })
  }

  isRealWeekInCart(realWeekId) {
    const cartContents = document.getElementById("cart-contents")
    return cartContents.innerHTML.includes(`data-real-week-id="${realWeekId}"`)
  }

  async submitOrder() {
    this.submitButtonTarget.disabled = true
    this.errorElementTarget.textContent = ""

    try {
      const response = await fetch("/parent/orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      })

      if (!response.ok) {
        throw new Error("Failed to create order")
      }

      const data = await response.json()

      const { error, paymentIntent } = await this.stripe.confirmCardPayment(
        data.client_secret,
        {
          payment_method: { card: this.card },
        }
      )

      if (error) {
        throw error
      }

      if (paymentIntent.status === "succeeded") {
        // Redirect to a "processing" or "thank you" page
        window.location = "/parent/cours_ponctuels/processing"
      } else {
        throw new Error("Payment not successful")
      }
    } catch (error) {
      console.error("Payment error:", error)
      this.errorElementTarget.textContent =
        error.message || "An error occurred. Please try again."
      this.submitButtonTarget.disabled = false
    }
  }

  addToCart(event) {
    console.log(event.type)
    this.updateAddToCartButtons()
  }
}
