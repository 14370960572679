import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["contents", "total"]

  connect() {
    console.log("Cart controller connected")
  }

  updateCart(event) {
    // The cart contents are automatically updated by Turbo
    // We just need to update the total
    this.updateTotal()
  }

  updateTotal() {
    const total = this.contentsTarget.querySelector("#cart-total")
    if (total) {
      this.totalTarget.textContent = total.textContent
    }
  }
}
