import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.initializeSwiper()
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }

  initializeSwiper() {
    this.swiper = new Swiper(this.containerTarget, {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 1000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    })
  }
}
