import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["option", "selectedItems"]

  connect() {
    const option = this.optionTarget
    new TomSelect(option, {
      maxOptions: this.element.dataset.maxOptions === "unlimited",
      render: {
        no_results: function (data, escape) {
          return '<div class="no-results">Aucun résultat trouvé</div>'
        },
        option: function (item, escape) {
          return `<div class="row border-bottom py-2">
                <div class="col-md-12">
                  <div class="mt-0">
                    <span class="text-muted ${
                      item.tutor ? "option-red fw-semibold" : ""
                    }"> ${escape(item.text)}</span>
                  </div>

                </div>
              </div>`
        },
      },
    })
  }
}
