import { Controller } from "@hotwired/stimulus"
import debounce from "debounce"

export default class extends Controller {
  static targets = ["searchInput"]
  connect() {
    const currentUrl = new URL(window.location.href)
    const queryParams = new URLSearchParams(currentUrl.search).get("query")
    if (queryParams === "") {
      currentUrl.searchParams.delete("query")
      window.history.replaceState({}, document.title, currentUrl.toString())
    }
  }

  disconnect() {
    this.searchInputTarget.removeEventListener("search", this.submit.bind(this))
    this.searchInputTarget.removeEventListener("click", this.submit.bind(this))
  }

  initialize() {
    const submitBtn = document.querySelectorAll("button[type=submit]")[0]
    submitBtn.addEventListener("click", (e) => {
      e.preventDefault()
      this.submit(e)
    })
    //
    const inputValue = this.searchInputTarget.value.trim()
    const currentUrl = new URL(window.location.href)
    currentUrl.searchParams.delete("query")

    this.searchInputTarget.addEventListener("search", (e) => {
      const inputValue = this.searchInputTarget.value.trim()

      if (inputValue === "") {
        // Get the current URL
        // Remove the 'query' parameter from the URL
        // Replace the current URL with the updated one (without the 'query' parameter)
      }
      this.submit(e)

      // window.history.replaceState({}, document.title, currentUrl.toString())
    })
  }

  submit(event) {
    event.preventDefault()
    this.element.submit()
  }
}
