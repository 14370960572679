import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back-to-top"
export default class extends Controller {
  onscroll = (el, listener) => {
    el.addEventListener("scroll", listener)
  }
  connect() {
    let backtotop = document.querySelector(".back-to-top")
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active")
        } else {
          backtotop.classList.remove("active")
        }
      }
      window.addEventListener("load", toggleBacktotop)
      this.onscroll(document, toggleBacktotop)
    }
  }
}
