import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inputSearch", "listGroup", "studentsSelected"]
  static values = {
    view: String,
  }
  connect() {
    this.filterStudents()
  }

  filterStudents() {
    const searchTerm = this.inputSearchTarget.value
      .toUpperCase()
      .replace(/\s/g, "")

    this.listGroupTargets.forEach((element) => {
      const studentName = element.dataset.studentName

      if (
        (studentName && studentName.toUpperCase().includes(searchTerm)) ||
        searchTerm.length === 0
      ) {
        element.classList.remove("d-none")
      } else {
        element.classList.add("d-none")
      }
    })
  }

  addStudent(event) {
    const checkbox = event.currentTarget
    const studentName = checkbox.parentElement.dataset.studentName

    if (checkbox.checked) {
      const studentHTML = `
        <div class="student-selected" data-student-selected="${studentName}">
          <span class="badge bg-info text-dark">
            ${studentName}
            <svg xmlns="http://www.w3.org/2000/svg" id="clickable" role="button" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
        </div>
      `
      this.studentsSelectedTarget.insertAdjacentHTML("beforeend", studentHTML)

      const closeButton = this.studentsSelectedTarget.querySelector(
        `.student-selected[data-student-selected="${studentName}"] svg#clickable`
      )

      closeButton.addEventListener("click", () => {
        checkbox.checked = false

        closeButton.parentElement.remove()
      })
    } else {
      const studentSelected = this.studentsSelectedTarget.querySelector(
        `.student-selected[data-student-selected="${studentName}"]`
      )
      if (studentSelected) {
        studentSelected.remove()
      }
    }
  }
}
