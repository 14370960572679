import { Controller } from "@hotwired/stimulus"
import { fr } from "date-fns/locale"
import "chartjs-adapter-date-fns"
export default class extends Controller {
  static values = {
    id: String,
  }
  connect() {
    const chart = Chartkick.charts["candidatesCharts"]

    // let _this = this

    let chartConfig = (chart.getChartObject().config.options = {
      scales: {
        x: {
          adapters: {
            date: {
              locale: fr,
            },
          },
          type: "time",
          time: {
            unit: "day",
            tooltipFormat: "dd/MM/yyyy",
            parser: "yyyy-mm-dd",
            unitStepSize: 1,
          },
        },
      },
    })
    this.chartConfig = chartConfig
    // chartConfig.options.onClick = function (event, native, active) {
    //   if (native.length > 0) {
    //     let xAxisIndex = native[0].index
    //     let label = chart.getChartObject().data.labels[xAxisIndex]

    //     _this.dateFilterValue = label
    //     _this.loadCharts()
    //   }
    // }
  }

  loadCharts() {
    console.log(
      "loadCharts called with this.dateFilterValue = ",
      this.dateFilterValue
    )
  }
}
