import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea", "currentCount", "maximumCount"]

  connect() {
    this.updateCount()
  }

  updateCount() {
    this.textareaTargets.forEach((element) => {
      if (
        (this.currentCountTarget &&
          this.currentCountTarget.id.replace(/\D+/g, "")) ===
        (element.id && element.id.replace(/\D+/g, ""))
      ) {
        const characterCount = element.value.length
        const currentCount = this.currentCountTarget
        const maximumCount = this.maximumCountTarget

        currentCount.textContent = characterCount

        if (characterCount < 70) {
          currentCount.style.color = "#666"
          maximumCount.style.color = "#666"
          maximumCount.style.fontWeight = "normal"
        } else if (characterCount < 90) {
          currentCount.style.color = "#6d5555"
          maximumCount.style.color = "#6d5555"
          maximumCount.style.fontWeight = "normal"
        } else if (characterCount < 100) {
          currentCount.style.color = "#793535"
          maximumCount.style.color = "#793535"
          maximumCount.style.fontWeight = "normal"
        } else if (characterCount < 120) {
          currentCount.style.color = "#841c1c"
          maximumCount.style.color = "#841c1c"
          maximumCount.style.fontWeight = "normal"
        } else if (characterCount <= 139) {
          currentCount.style.color = "red"
          maximumCount.style.color = "red"
          maximumCount.style.fontWeight = "bold"
        } else {
          maximumCount.style.color = "#8f0001"
          currentCount.style.color = "#8f0001"
          maximumCount.style.fontWeight = "bold"
        }
      }
    })

    // const characterCount = textArea.value?.length ?? 0
    // const currentCount = this.currentCountTarget
    // const maximumCount = this.maximumCountTarget

    // currentCount.textContent = characterCount
  }
}
