import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.hideTableOnBeforeUnload()
    this.calculateTotals()
    this.hideLoadingSvg()

    this.formTarget.addEventListener("submit", (event) => {
      this.showLoadingSpinner()
    })
  }

  hideTableOnBeforeUnload() {
    window.onbeforeunload = () => {
      this.financeComponentTarget.classList.add("visually-hidden")
      this.loadingSvgTarget.classList.remove("visually-hidden")
    }
  }

  calculateTotals() {
    for (let i = 1; i <= 12; i++) {
      let sum = 0
      this.element
        .querySelectorAll(`tbody tr td:nth-child(${i}) span`)
        .forEach((element) => {
          const value = parseFloat(element.textContent)
          sum += value
        })
      this.footerTarget.children[i - 1].textContent = sum.toFixed(2)
    }

    // Update the "Total:" text within the <th> tag
    this.totalHeaderTarget.textContent = "Total:"
  }

  showLoadingSpinner() {
    this.financeComponentTarget.classList.add("visually-hidden")
    this.loadingSvgTarget.classList.remove("visually-hidden")
  }

  hideLoadingSvg() {
    this.loadingSvgTarget.classList.add("visually-hidden")
    this.financeComponentTarget.classList.remove("visually-hidden")
  }

  get tableTarget() {
    return this.element.querySelector("#financial-report-table")
  }
  get financeComponentTarget() {
    return this.element.querySelector("#finance-component")
  }

  get footerTarget() {
    return this.element.querySelector("tfoot tr")
  }

  get loadingSvgTarget() {
    return this.element.querySelector("#loading__svg")
  }

  get totalHeaderTarget() {
    return this.element.querySelector("tfoot th[colspan='1']")
  }

  get formTarget() {
    return this.element.querySelector("form")
  }
}
