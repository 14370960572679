import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back-to-top"
export default class extends Controller {
  connect() {
    const btnCreateTlms = document.getElementById("create-tlms")
    btnCreateTlms.addEventListener("click", (e) => {
      const id = e.target.getAttribute("data-id-candidate")
      this.createTlms(id)
    })

    const btnRecruited = document.getElementById("recruited-candidate")
    btnRecruited.addEventListener("click", (e) => {
      const id = e.target.getAttribute("data-id-candidate")
      this.recruitedCandidate(id)
    })

    const btnFired = document.getElementById("fired-candidate")
    if (btnFired) {
      btnFired.addEventListener("click", (e) => {
        const id = e.target.getAttribute("data-id-candidate")
        this.firedCandidate(id)
      })
    }

    const bntSelected = document.getElementById("selected-candidate")
    bntSelected.addEventListener("click", (e) => {
      const id = e.target.getAttribute("data-id-candidate")
      this.selectedCandidate(id)
    })
  }

  createTlms(id) {
    fetch(`/administration/candidates/${id}/create_talent_lms_account`, {
      contentType: "application/json",
      hearders: "application/json",
      method: "POST",
      body: JSON.stringify({ id: id }),
    }).then((res) => {
      console.log(res)
    })
  }

  recruitedCandidate(id) {
    fetch(`/administration/candidates/${id}/recruited`, {
      contentType: "application/json",
      hearders: "application/json",
      method: "POST",
      body: JSON.stringify({ id: id }),
    }).then((res) => {
      console.log(res)
    })
  }

  firedCandidate(id) {
    fetch(`/administration/candidates/${id}/fired`, {
      contentType: "application/json",
      hearders: "application/json",
      method: "POST",
      body: JSON.stringify({ id: id }),
    }).then((res) => {
      console.log(res)
    })
  }
  selectedCandidate(id) {
    fetch(`/administration/candidates/${id}/selected`, {
      hearders: {
        contentType: "application/json",
      },
      method: "POST",
      body: JSON.stringify({ id: id }),
    }).then((res) => {
      console.log(res)
    })
  }
}

// $(document).on('click', '.dropdown-menu .list-fire .fire-cand', function(e) {
//   let candidate_data = $(this).closest('.dropdown.action-settings').data("cand")
//   let tr = $(this).closest('tr')
//   let cand_id = candidate_data.replace("cand_", "")
//   let current_page = cand_table.page.info().page
//   $.ajax({
//       type: 'POST',
//       dataType: 'json',
//       url: '/administration/candidates/' + cand_id + '/fired',
//       success: function(response) {
//         cand_table.page(current_page).row( tr ).invalidate().draw('page');
//       }
//   });
// });
