import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "coursesAdvanced",
    "coursesMinimized",
    "coursesError",
    "customCoursesTrue",
    "customCoursesFalse",
  ]

  connect() {
    this.updateCourseDisplay()
    this.customCoursesTrueTarget.checked = true
  }

  toggleCourseDisplay() {
    this.updateCourseDisplay()
  }

  updateCourseDisplay() {
    if (this.customCoursesTrueTarget.checked) {
      console.log("true")
      this.coursesAdvancedTarget.classList.remove("d-none")
      this.coursesMinimizedTarget.classList.add("d-none")
    } else if (this.customCoursesFalseTarget.checked) {
      this.coursesAdvancedTarget.classList.add("d-none")

      this.coursesMinimizedTarget.classList.remove("d-none")
    }
  }

  updateCourseCheckboxes(event) {
    const courseId = event.target.dataset.courseId
    const isChecked = event.target.checked

    this.element.querySelector(`#student_courses_a_ids_${courseId}`).checked =
      isChecked
    this.element.querySelector(`#student_courses_b_ids_${courseId}`).checked =
      isChecked
  }

  validateForm(event) {
    const checkedCourses = this.element.querySelectorAll(
      'input[id^="student_courses_a_ids_"]:checked'
    )
    if (checkedCourses.length === 0) {
      event.preventDefault()
      this.coursesErrorTarget.style.display = "block"
      setTimeout(() => {
        this.element.querySelector("input[type=submit]").disabled = false
        this.element.querySelector("input[type=submit]").value = "Enregistrer"
      }, 500)
    }
  }
}
