import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "../helpers"

export default class extends Controller {
  static targets = ["button", "form"]

  sendSms(event) {
    event.preventDefault()

    const form = this.formTarget;

    fetch(form.action, {
      method: "POST",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token"),
        Accept: "application/javascript",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(new FormData(form)),
    })
      .then((response) => response.text())
      .then(() => {
        this.buttonTarget.disabled = true
        this.buttonTarget.textContent = "En cours..."

        setTimeout(() => {
          this.buttonTarget.disabled = false
          this.buttonTarget.textContent = "Renvoyer"
        }, 5000)
      })
  }
}
