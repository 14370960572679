import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "button"]
  static values = { maxHeight: Number }

  initialize() {
    this.expanded = false
    if(this.contentTarget.offsetHeight < 388){
      this.buttonTarget.remove()
    }
  }

  toggle() {
    this.expanded = !this.expanded
    if (this.expanded) {
      this.contentTarget.classList.remove("card-max-height")
    } else {
      this.contentTarget.classList.add("card-max-height")
    }
    // if(this.contentTarget && this.contentTarget.style.maxHeight)
    this.buttonTarget.innerText = this.expanded ? "Show Less" : "Show More"
  }
}
