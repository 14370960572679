import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="concat-inputs"
export default class extends Controller {
  static targets = ["hour", "minute", "form"]
  connect() {
    this.formTarget.addEventListener("submit", (e) => {
      // e.preventDefault()
      this.updateHiddenField()
    })
  }

  updateHiddenField() {
    const hour = this.hourTarget.value
    const minute = this.minuteTarget.value
    const concatenatedValue = `${hour}:${minute}`
    this.formTarget.querySelector('[name="private_session[duration]"]').value =
      concatenatedValue
  }

  submitForm() {
    this.updateHiddenField() // Update the hidden field before submitting
    this.formTarget.submit() // Submit the form
  }
}
