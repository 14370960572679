import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "absRadio",
    "repRadio",
    "noCorrespondance",
    "replacementWarning",
  ]

  submit(event) {
    const error = !(
      this.hasCheckedRadio() &&
      (this.hasCheckedRepRadio() || this.hasCheckedNoCorrespondance())
    )
    console.log("abs_radio", this.hasCheckedRadio())
    //console.log("no_correspondance", this.hasCheckedNoCorrespondance())
    console.log("rep_radio", this.hasCheckedRepRadio())

    if (error) {
      this.replacementWarningTarget.textContent =
        "Il faut choisir une séance et un remplacement"
      event.preventDefault()
      return false
    }
  }

  noCorrespondanceChange() {
    if (this.hasCheckedNoCorrespondance()) {
      this.repRadioTargets.forEach((radio) => (radio.disabled = true))
    } else {
      this.repRadioTargets.forEach((radio) => (radio.disabled = false))
    }
  }

  hasCheckedRadio() {
    return this.absRadioTargets.some((radio) => radio.checked)
  }

  hasCheckedRepRadio() {
    return this.repRadioTargets.some((radio) => radio.checked)
  }

  hasCheckedNoCorrespondance() {
    return this.noCorrespondanceTarget.checked
  }
}
