import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["comment", "commentCheckbox"]

  connect() {
    if (this.hasCommentCheckboxTarget && this.commentCheckboxTarget.checked) {
      this.commentTarget.classList.remove("d-none")
    }
  }

  toggleComment(event) {
    if (this.hasCommentTarget) {
      if (event.target.checked) {
        this.commentTarget.classList.remove("d-none")
        // make textarea required
        console.log('on')
        this.commentTarget.required = true
      } else {
        this.commentTarget.classList.add("d-none")
        console.log('off')
        this.commentTarget.required = false
      }
    }
  }
}
